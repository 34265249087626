import { useState, useMemo } from 'react'

import { captureException } from '@sentry/browser'
import {
  MAX_FILE_SIZE_MIB,
  MEBIBYTE,
  SUPPORTED_FILE_MIME_EXTENSION_TYPES,
} from 'api/src/common/constants'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import {
  CreateStorageObjectMutation,
  CreateStorageObjectMutationVariables,
  UpdateStorageObjectMutation,
  UpdateStorageObjectMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/Providers'

import { CREATE_STORAGE_OBJECT, UPDATE_STORAGE_OBJECT } from './utils'

interface UseUploadStorageObjectInput {
  prefixes: string[]
  fileName?: string
  isGlobal?: boolean
  allowedFileMimeTypes: SUPPORTED_FILE_MIME_TYPES[]
}

interface Error {
  message: string
}

const useUploadStorageObject = ({
  prefixes,
  fileName,
  isGlobal = false,
  allowedFileMimeTypes,
}: UseUploadStorageObjectInput) => {
  const { currentUser } = useAuth()

  const { clientId } = useMemo(
    () => ({
      clientId: currentUser.parentData.id,
    }),
    [currentUser],
  )

  const [error, setError] = useState<Error>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] =
    useState<UpdateStorageObjectMutation['updateStorageObject']>(null)

  const [createStorageObjectMutation] = useMutation<
    CreateStorageObjectMutation,
    CreateStorageObjectMutationVariables
  >(CREATE_STORAGE_OBJECT)

  const [updateStorageObjectMutation] = useMutation<
    UpdateStorageObjectMutation,
    UpdateStorageObjectMutationVariables
  >(UPDATE_STORAGE_OBJECT)

  const uploadFile = async ({ file }: { file: File }) => {
    // Reset states
    setError(null)
    setResult(null)
    setLoading(true)

    // We should have a file
    if (!(file instanceof File)) {
      throw new Error(`Expected a File, but received ${typeof file}`)
    }

    const fileExt = file.name.split('.').pop()

    // Confirm the file has a supported mime type and extension
    const fileMimeType = file.type.split('/')[0]
    const fileMimeExt = file.type.split('/')[1]

    const fileIsSupported =
      allowedFileMimeTypes.includes(
        fileMimeType as SUPPORTED_FILE_MIME_TYPES,
      ) &&
      SUPPORTED_FILE_MIME_EXTENSION_TYPES[fileMimeType].includes(fileMimeExt)

    if (!fileIsSupported) {
      setError({
        message: `File type '${file.type}' not supported. Only ${allowedFileMimeTypes.join(', ')} are supported`,
      })
      setLoading(false)
      return
    }

    // Check File size constraints
    if (file.size > MAX_FILE_SIZE_MIB[fileMimeType] * MEBIBYTE) {
      setError({
        message: `Maximum file size allowed is ${MAX_FILE_SIZE_MIB[fileMimeType]} MiB`,
      })
      setLoading(false)
      return
    }

    let strippedFileName: string

    strippedFileName = fileName || file.name

    // remove fileType from file name -> i.e. .pdf, .docx, .png, etc
    strippedFileName = strippedFileName.replace(/\.[^/.]+$/, '')

    try {
      const createResponse = await createStorageObjectMutation({
        variables: {
          input: {
            prefixes,
            fileName: strippedFileName,
            fileExt,
            metadata: {
              sizeBytes: file.size,
              mimetype: file.type,
            },
            clientId,
            isGlobal,
          },
        },
      })

      await fetch(createResponse.data.createStorageObject.uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'content-type': file.type,
        },
      })

      const updateResponse = await updateStorageObjectMutation({
        variables: {
          id: createResponse.data.createStorageObject.id,
          input: {
            uploaded: true,
          },
        },
      })

      setResult(updateResponse.data.updateStorageObject)
      setLoading(false)

      return updateResponse.data.updateStorageObject
    } catch (error) {
      captureException(error)
      setError({ message: 'An unexpected error occurred' })
      setLoading(false)
    }
  }

  return [uploadFile, { result, error, loading }] as const
}

export default useUploadStorageObject
